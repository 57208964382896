@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --color-dark-navy: #1e0f3c;
  --color-teal: #00a063;
  --color-soft-cyan: #42f5f5;
  --color-cool-gray: #e8eff7;
  --color-white-smoke: #f5f7fa;
  --color-accent: #ff6b6b;
  --color-meme-yellow: #ffd700;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.glow-text {
  text-shadow:
    0 0 10px var(--color-soft-cyan),
    0 0 20px var(--color-soft-cyan),
    0 0 30px var(--color-soft-cyan);
}

.blog-card {
  perspective: 1000px;
}

.blog-card:hover {
  transform: rotateY(5deg) scale(1.05);
}

.shadow-neon {
  box-shadow:
    0 0 10px var(--color-soft-cyan),
    0 0 20px var(--color-teal);
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

.blog-card {
  animation: float 5s ease-in-out infinite;
}

.bg-gradient-to-br {
  background: linear-gradient(to bottom right, var(--color-white-smoke), var(--color-cool-gray));
}

@keyframes psychedelic {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-psychedelic {
  background: linear-gradient(45deg, #ff00ff, #00ffff, #ff00ff, #ffff00, #00ff00, #ff00ff);
  background-size: 400% 400%;
  animation: psychedelic 5s ease infinite;
}

@keyframes glitch {
  0% {
    text-shadow:
      0.05em 0 0 #00fffc,
      -0.05em -0.025em 0 #fc00ff,
      0.025em 0.05em 0 #fffc00;
  }
  14% {
    text-shadow:
      0.05em 0 0 #00fffc,
      -0.05em -0.025em 0 #fc00ff,
      0.025em 0.05em 0 #fffc00;
  }
  15% {
    text-shadow:
      -0.05em -0.025em 0 #00fffc,
      0.025em 0.025em 0 #fc00ff,
      -0.05em -0.05em 0 #fffc00;
  }
  49% {
    text-shadow:
      -0.05em -0.025em 0 #00fffc,
      0.025em 0.025em 0 #fc00ff,
      -0.05em -0.05em 0 #fffc00;
  }
  50% {
    text-shadow:
      0.025em 0.05em 0 #00fffc,
      0.05em 0 0 #fc00ff,
      0 -0.05em 0 #fffc00;
  }
  99% {
    text-shadow:
      0.025em 0.05em 0 #00fffc,
      0.05em 0 0 #fc00ff,
      0 -0.05em 0 #fffc00;
  }
  100% {
    text-shadow:
      -0.025em 0 0 #00fffc,
      -0.025em -0.025em 0 #fc00ff,
      -0.025em -0.05em 0 #fffc00;
  }
}

.glitch-text {
  animation: glitch 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
